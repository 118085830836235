export default [
  {
    projectName: 'alpa',
    title: 'Alpa',
    route: { name: 'project-alpa' },
    icon: 'CloudIcon',
    reportUPR: 'https://toledo-staging-alpa.wlabel.site/report/?test=',
  },
  {
    projectName: 'thor',
    title: 'Thor',
    route: { name: 'project-thor' },
    icon: 'CommandIcon',
    reportUPR: 'https://toledo-staging-thor-frontera.wlabel.site/report/?test=',
  },
  {
    projectName: 'king',
    title: 'King',
    route: { name: 'project-king' },
    icon: 'ShieldIcon',
    reportUPR: 'https://toledo-staging.king-wlabel.com/report?test=',
  },
]
