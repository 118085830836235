import Vue from 'vue'
import VueRouter from 'vue-router'
import appConfig from '@core/app-config'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    ...appConfig.map(project => ({
      path: `/${project.projectName}`,
      name: `project-${project.projectName}`,
      component: () => import('@/views/ProjectPage.vue'),
      props: {
        project: project.projectName,
      },
      meta: {
        pageTitle: `Project ${project.title}`,
        breadcrumb: [
          {
            text: 'Project Page',
            active: true,
          },
        ],
      },
    })),

    ...appConfig.map(project => ({
      path: `/${project.projectName}/reference`,
      name: `reference-page-${project.projectName}`,
      component: () => import('@/views/ReferencePage.vue'),
      props: true,
      meta: {
        pageTitle: 'Report page',
        project: project.projectName,
        breadcrumb: [
          {
            to: { name: `project-${project.projectName}` },
            text: 'Project page',
          },
          {
            text: 'Reference page',
            active: true,
          },
        ],
      },
    })),

    ...appConfig.map(project => ({
      path: `/report/${project.projectName}/:test`,
      name: `report-page-${project.projectName}`,
      component: () => import('@/views/ReportPage.vue'),
      props: true,
      meta: {
        pageTitle: 'Report page',
        project: `${project.projectName}`,
        breadcrumb: [
          {
            to: { name: `project-${project.projectName}` },
            text: 'Project page',
          },
          {
            text: 'Report page',
            active: true,
          },
        ],
      },
    })),
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
